import React, {useState} from 'react';
import {haptic, tg, username} from "../pages/Go";
import './Header.css';
import Tutorial from "./Tutorial";
import Boost from "./Boost";

const dots = [1,2,3,4];

const Header = ({active, h1Ref, balance, setBalance, power, setPower, referralsCount, authRequest, wallet, storyModal, setStoryModal}) => {

    const [tutorial, setTutorial] = useState(false);

    const [boostModal, setBoostModal] = useState(false);


    return (

        <div className={'header-wrapper'}>

            {/*<p className={'white header-top-p'}>
                TONGO Is Here!
            </p>*/}

            <Boost boostModal={boostModal} setBoostModal={setBoostModal} setBalance={setBalance} power={power} authRequest={authRequest} userWallet={wallet}/>


            <Tutorial
                tutorial={tutorial}
                setTutorial={setTutorial}
            />


            {/*<div className={'welcome-top-dots-container header'}>
                {
                    dots.map((item, index) =>
                        <div className={`${1 === index ? 'active' : ''} welcome-top-dot`} key={index}>

                        </div>
                    )
                }
            </div>*/}

            {/*<div className={'header-left'}>
                <h3 className={''}>
                    TONGO HERE
                </h3>
                <div className={'header-left-bottom'}>
                    <p className={'white'}>
                        Watch a tutorial!
                    </p>
                </div>
            </div>*/}

            <button
                className={'full btn noshadow purple'}
                onClick={() => {
                    haptic.impactOccurred('light');
                    setBoostModal(true)
                }}
            >
                GO BOOST
            </button>

            <button
                className={'full btn noshadow purple empty'}
                onClick={() => {
                    haptic.impactOccurred('light');
                    setTutorial(true)
                }}
            >
                TUTORIAL
            </button>

        </div>
    );
};

export default Header;