import React, {useState} from 'react';
import './Navbar.css'
import {Link} from "react-router-dom";
import {haptic} from "../pages/Go";

const navItems = ['HOME', 'BOARD', 'RANK']

const Navbar = ({navActive, setNavActive, scrollTop}) => {



    return (
        <div className={'navbar-wrapper-big'} /*style={{zIndex: navActive === 0 ? 0 : 0}}*/>

            <div className={'navbar-wrapper'}>

                {
                    navItems.map((item, i) =>
                        <div
                            className={`${i === navActive ? 'active' : ''} navbar-item`}
                            onTouchEnd={() => {
                                setNavActive(i);
                                scrollTop();
                                haptic.impactOccurred('light');
                            }}
                            key={i}
                        >
                            <h3>
                                {item}
                            </h3>
                        </div>
                    )
                }

            </div>

        </div>
    );
};

export default Navbar;