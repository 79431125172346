import React, {useState} from 'react';
import './Main.css';
import '../components/Modal.css';
import Story from "../components/Story";
import {first_name, haptic, id, is_premium, last_name, start_param, tg, url, username} from "./Go";
import logo from '../assets/logo_dry.svg';
import logo_ears from '../assets/logo_ears.svg';
import coin from '../assets/coin.svg';
import ProgressBar from "../components/ProgressBar";
import {AnimatePresence, motion} from "framer-motion"
import axios from "axios";
import Boost from "../components/Boost";
import Levels from "../components/Levels";

const Main = ({active, h1Ref, balance, setBalance, power, setPower, referralsCount, authRequest, wallet, storyModal, setStoryModal, level}) => {




    const [boostModal, setBoostModal] = useState(false);
    const [levelsModal, setLevelsModal] = useState(false);



    return (
        <>

            {active && (
                <>
                    <Story storyModal={storyModal} setStoryModal={setStoryModal} setBalance={setBalance} power={power}/>
                    {/*<Boost boostModal={boostModal} setBoostModal={setBoostModal} setBalance={setBalance} power={power} authRequest={authRequest} userWallet={wallet}/>*/}
                    <Levels levelsModal={levelsModal} setLevelsModal={setLevelsModal} referralsCount={referralsCount} userLevel={level}/>
                </>
            )}

            {active && (
                <motion.div
                    className={'home main-wrapper'}
                    key="go"
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    style={{
                        opacity: active ? 1 : 0,
                        filter: active ? 'brightness(100%)' : 'brightness(0%)',
                        // display: active ? 'flex' : 'none',
                    }}
                >


                    <div className={'main-top'}>

                        <div className={'dummie'}></div>

                        <div className={'balance-container'}>
                            <h1 id={'hh1'} className={'balance-h1 purple'}>
                                {/*<img src={coin} alt="coin" />*/}
                                {balance.toLocaleString(undefined, {maximumFractionDigits: 0})}
                                {/*&nbsp;*/}
                                <span className={'white'}>GO</span>
                            </h1>


                            <div className={'go-power-wrapper'}>
                                <div className={'btn flexible small gray first'}>
                                    {power}&nbsp;<span className={'purple'}>GO Power</span>
                                </div>
                                {/*<div
                                    className={'btn flexible small purple second'}
                                    onClick={() => setBoostModal(true)}
                                >
                                    <span className={'black'}>GO Boost</span>
                                </div>*/}
                            </div>


                        </div>


                        <motion.div
                            /*whileTap={{scale: 0.98}}
                            transition={{
                                duration: 0.025,
                                ease: [1, 0, 0, 1],
                            }}*/
                            className={'go-button-wrapper'}
                            onTouchEnd={() => {
                                setStoryModal(true);
                                haptic.impactOccurred('light');
                            }}
                        >
                            <div className={'go-button'}>
                                <img src={logo}/>
                                <img src={logo_ears}/>
                            </div>
                        </motion.div>


                        <ProgressBar referralsCount={referralsCount} setLevelsModal={setLevelsModal} level={level}/>

                        <div className={'dummie'}></div>

                    </div>

                </motion.div>
            )}
        </>
    );
};

export default Main;