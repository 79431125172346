import React, {useEffect, useState} from 'react';
import './Tasks.css';
import axios from "axios";
import {convertNumberToShortString, haptic, id, tg, url} from "../pages/Go";
import hi from '../assets/hi_illust.svg';





export const levelsSchema = [
    {
        level: 1,
        referrals: 10,
        power: 360
    },
    {
        level: 2,
        referrals: 25,
        power: 480
    },
    {
        level: 3,
        referrals: 50,
        power: 720
    },
    {
        level: 4,
        referrals: 100,
        power: 960
    },
    {
        level: 5,
        referrals: 250,
        power: 1200
    },
    {
        level: 6,
        referrals: 500,
        power: 1560
    },
    {
        level: 7,
        referrals: 1000,
        power: 1920
    },
    {
        level: 8,
        referrals: 2500,
        power: 2400
    },
    {
        level: 9,
        referrals: 5000,
        power: 2880
    },
    {
        level: 10,
        referrals: 10000,
        power: 3360
    },
]





const Levels = ({levelsModal, setLevelsModal, referralsCount, userLevel}) => {




    return (


        <>
            <div className={`${levelsModal ? 'active' : ''} modal-overlay`} onClick={() => setLevelsModal(false)}></div>
            <div className={`${levelsModal ? 'active' : ''} autoheight tasks-wrapper modal-wrapper`}>


                <div className={'story-container-top'}>


                    <div className={'header-left'}>
                        <h3 className={'white'}>
                            LEVELS
                        </h3>
                        <div className={'header-left-bottom'}>
                            <p className={'white'}>
                                Increase GO POWER by inviting Friends
                            </p>
                        </div>
                    </div>

                </div>


                <div className={'tasks-container'} style={{marginTop: '12px', display: 'grid', gridTemplateColumns: 'repeat(2,1fr)'}}>

                    {
                        levelsSchema.map((level, index) =>

                            <div
                                className={'task-item'}
                                key={index}
                                style={{
                                    background: level.referrals <= levelsSchema.find(l => l.level === userLevel)?.referrals ? 'var(--purple)' : 'var(--graylight)',
                                    boxShadow: level.referrals <= levelsSchema.find(l => l.level === userLevel)?.referrals ? '' : '0px 0px 0px rgba(0,0,0,0)',
                                }}
                                // style={{background: '#fff'}}
                            >

                                <div className={'task-item-left'}>

                                    <h3 className={`bold ${level.referrals <= levelsSchema.find(l => l.level === userLevel)?.referrals ? 'black' : 'white'}`}>
                                        {convertNumberToShortString(level.referrals)} FRIENDS
                                    </h3>
                                    <p className={`${level.referrals <= levelsSchema.find(l => l.level === userLevel)?.referrals ? 'black' : 'white'}`}>
                                        {level.power} Base Power
                                    </p>
                                </div>

                                <p className={`bold ${level.referrals <= levelsSchema.find(l => l.level === userLevel)?.referrals ? 'black' : 'white'}`} style={{marginRight: '3px', marginBottom: '2px'}}>
                                    {index+1}
                                </p>

                            </div>
                        )
                    }

                </div>

                <button
                    className={'btn full white noshadow'}
                    onTouchEnd={() => {
                        setLevelsModal(false);
                        haptic.impactOccurred('light');
                    }}
                >
                    CLOSE
                </button>

            </div>
        </>


    );
};

export default Levels;